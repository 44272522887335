.fade-in {
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: 0.5s;
    transition-delay: 1s;
    transition-timing-function: ease-in;
}

.fade-in.up {
    transform: translateY(100px);
}

.fade-in.down {
    transform: translateY(-100px);
}

.fade-in.show {
    opacity: 1;
    transform: translateY(0);
}
