@font-face {
    font-family: 'Montserrat';
    src: local('MontserratRegular'), url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: local('MontserratSemiBold'), url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-display: swap;
    font-weight: 600;
}

@font-face {
    font-family: 'Montserrat';
    src: local('MontserratExtraBold'), url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-display: swap;
    font-weight: 800;
}

@font-face {
    font-family: 'PlusJakarta';
    src: local('MontserratRegular'), url('../assets/fonts/PlusJakartaSans-Regular.ttf') format('truetype');
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: 'PlusJakarta';
    src: local('MontserratSemiBold'), url('../assets/fonts/PlusJakartaSans-SemiBold.ttf') format('truetype');
    font-display: swap;
    font-weight: 600;
}

@font-face {
    font-family: 'PlusJakarta';
    src: local('MontserratExtraBold'), url('../assets/fonts/PlusJakartaSans-Bold.ttf') format('truetype');
    font-display: swap;
    font-weight: 800;
}
