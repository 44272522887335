*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html,
body,
#root {
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
    font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}
